.loader{
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    margin-left: 10%;
  }
  
 
  svg path,
  svg rect{
    fill: #FF6700;
  }