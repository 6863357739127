.span-3 {
    grid-column: span 3;
}
.image-grid-row-2 {
    grid-row: span 2;
}
.img{
    width: 100%;
    max-width: 15rem;
    height:10rem;
   }
   .hmm-pg {
    width: 100%;
    
}
   .hmm-pg::before {
    content: '';
     position: absolute; 
    top: 72px;
    left: 0px;
    width: 100%;
    height: 80vh;
    background: url("https://www.starestate.in/assets/images/homebanner/banner-new-4k.jpg") ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
}   