.h-wrapper{
    background: var(--black);
    color: white;
}
.h3{
    margin-top: 10%;
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}

.h-menu{
    gap: 2rem;

}
.h-menu>*:hover{
    cursor:pointer;
}
.menu-icon{
    display: none;
}
@media (max-width:768px){
    .menu-icon{
        display: block;
    }
    .h-menu{
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
    }
}
 .pro{
    margin-top: 25%;
    text-align: center;
    margin: 10px;
}