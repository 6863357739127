* {
    margin: 0px;
    padding: 0px;
}

.hm-pg {
    width: 100%;
}
.r-head{
    margin-bottom: 2rem;
   }

.hm-pg::before {
    content: '';
    position: absolute;
    top: 72px;
    left: 0px;
    width: 100%;
    height: 80vh;
     
    background: linear-gradient(to left, rgba(0,0,0,0.7),rgba(54, 54, 54, 0.5)),url("https://images.squarespace-cdn.com/content/v1/5c48f0b6f8370ad5312e34b2/1554309262413-73XD08P37U8TODBYRO9Z/ev-pool-house-banner.jpg?format=2500w") ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
}



.homebox {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 162px;
    margin: auto;
    width: 60%;
    height: 60vh;
}

@media (max-width: 1200px) {
    .homebox {
        width: 60%;
    }
}

@media (max-width: 1000px) {
    .homebox {
        width: 64%;
    }
}

@media (max-width: 830px) {
    .homebox {
        width: 68%;
    }
}

@media (max-width: 456px) {
    .homebox {
        width: 80%;
    }
}

@media (max-width: 356px) {
    .homebox {
        width: 90%;
    }
}

@media (max-width: 320px) {
    .homebox {
        width: 94%;
    }
}