 .r-container{
  overflow: hidden;
  position: relative;
  
 }
 .r-head{
  margin-bottom: 2rem;
 }
 .r-card{
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  margin: auto;
  transition: all 300ms ease-in;
  height: 25rem;
 }
 .r-card>img{
  width: 100%;
  max-width: 15rem;
  height:10rem;
 }
.r-card>:nth-child(2){
  font-size: 1rem;
  font-weight: 600;
}
.r-card:hover{
  scale: 1.025;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(255,255,255,0)0%,
    rgba(136,160,255,0.46)217.91%
  );
  box-shadow: 0px 72px 49px -51px rgba(136,160,255,0.21);
}
.swiper-horizontal{
  overflow: visible;
}
.r-buttons{
  position: absolute;
  gap: 1.5rem;
  right: 0;
  top: -4rem;
}
.r-buttons button{
  font-size: 1.2rem;
  padding: 0.2rem 0.8rem;
  color: blue;
  border: none;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.r-buttons>:nth-child(1){
  background-color: #EEEEFF;

}
.r-buttons>:nth-child(2){
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}
.searchField{
  position: absolute;
  top: -20%;
  width: 35%;
}